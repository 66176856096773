<!--
 * @Author: inwen6
 * @Date: 2020-12-10 09:27:46
 * @LastEditTime: 2020-12-18 18:06:10
 * @LastEditors: your name
 * @Description: 新闻页面
 * @FilePath: /hheiqngting-gw/src/views/news.vue
-->
<template>
  <div class="news">
    <div class="newsBanner">
      <video
        autoplay="autoplay"
        muted
        loop
        :src="bannerData.bannerUrl"
        v-if="bannerData.type == 2"
      ></video>
      <el-image
        class="img"
        :src="bannerData.bannerUrl"
        fit="cover"
        v-if="bannerData.type == 1"
      ></el-image>
    </div>
    <div class="newBox">
      <div class="title">
        <span v-animation:home="'a-fadeinT'">新闻资讯</span>
      </div>
      <div class="colBox flex">
        <div class="li flex hvr-sweep-to-right" @click="ddclick(1)">
          企业动态
        </div>
        <div class="li flex hvr-sweep-to-right" @click="ddclick(2)">
          案例新闻
        </div>
        <div class="li flex hvr-sweep-to-right" @click="ddclick(3)">
          蜻蜓视角
        </div>
      </div>
      <div class="newsList">
        <div class="listBox">
          <div
            class="liBox flex"
            v-for="(item, index) in filterList"
            :key="index"
            @click="jumpRouter(item.newsId)"
          >
            <div class="liImgBox">
              <el-image
                class="img"
                v-animation="'testImg'"
                :src="item.coverImgUrl"
              ></el-image>
            </div>
            <div class="liDetail" v-animation="'liDetailAN'">
              <div class="detailTitle">{{ item.title }}</div>
              <div class="time">{{ item.createTime }}</div>
              <div v-html="item.detail" class="detail1"></div>
              <div class="more hvr-sweep-to-right">MORE</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <foo></foo>
    </div>
  </div>
</template>
<script>
import foo from "../components/HelloWorld";
import { banner, news } from "@/api/api_list";
export default {
  components: { foo },
  data() {
    return {
      bannerData: [],
      newsList: [],
      filterList: [],
    };
  },
  methods: {
    jumpRouter(id) {
      this.$router.push({
        query: { id },
        path: "/newsDetail",
      });
    },
    filtersNewsData(data, type) {
      /*
          data 数据源
          type 1 企业动态 2案例视角 3蜻蜓视角
          data.isHomeShow 1展示 0不展示
        */
      if (!data) return [];
      return data.filter((row) => row.type == type && row.isHomeShow == 1);
    },
    ddclick(type) {
      this.filterList = this.filtersNewsData(this.newsList, type);
    },
  },
  created() {
    banner({ location: 5 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
    news({ pageNum: 1, pageSize: 12 }).then((res) => {
      if (res.code == 200) {
        this.newsList = res.data.list;
        var arr = this.filtersNewsData(this.newsList, 1);
        // if (arr.length > 0) {
        //   for (var i = 0; i < arr.length; i++) {
        //     var item = arr[i].detail;
        //     item.replace("rgb(255, 68, 68);", "rgb(255, 255, 255);");
        //     console.log("列表12", item);
        //   }
        // }
        this.filterList = arr;
        console.log("列表", this.filterList);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.news {
  width: 100%;
  height: 400px;
  .newsBanner {
    width: 100%;
    height: 100%;
    background: black;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .newBox {
    width: 100%;
    background: white;
    // height: 300px;
    .title {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      padding: 50px 0;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: bold;
      color: black;
    }
    .colBox {
      width: 100%;
      height: 100px;
      .li {
        width: 200px;
        height: 44px;
        font-size: 12px;
        color: #999;
        border: 1px solid rgb(230, 230, 230);
        margin-right: 10px;
        animation-fill-mode: none;
        cursor: pointer;
      }
      .li:hover {
        color: #999;
        transform: scale(1);
      }
    }
    .newsList {
      width: 100%;
      // height: 500px;
      display: flex;
      justify-content: center;
      .listBox {
        width: 800px;
        min-width: 800px;
        // background: cadetblue;
        .liBox {
          width: 100%;
          height: 140px;
          // background: #1f1f1f;
          border-bottom: 1px solid #999;
          .liImgBox {
            width: 180px;
            height: 140px;
            // background: chocolate;
            box-sizing: border-box;
            padding: 20px;
            cursor: pointer;
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
              transition: all 0.6s;
            }
          }
          .liDetailAN {
            animation: liDetailAN1 1s;
          }
          @keyframes liDetailAN1 {
            0% {
              transform: translateX(1000px);
            }
            100% {
              transform: translateX(0px);
            }
          }
          .liDetail {
            width: calc(100% - 180px);
            height: 140px;
            // background: skyblue;
            flex-direction: column;
            cursor: pointer;
            box-sizing: border-box;
            padding: 10px;
            .detailTitle {
              width: 100%;
              height: 20px;
              line-height: 20px;
              // background: chartreuse;
              font-size: 12px;
              color: black;
            }
            .time {
              width: 100%;
              color: #999;
              font-size: 10px;
              margin: 10px 0;
            }
            .detail {
              width: 100%;

              font-size: 10px;
              height: 30px;
              line-height: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box; //作为弹性伸缩盒子模型显示。
              -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
              -webkit-line-clamp: 2; //显示的行
            }
            .more {
              width: 64px;
              height: 20px;
              border: 1px solid rgb(180, 179, 179);
              line-height: 20px;
              text-align: center;
              color: sandybrown;
              margin-top: 20px;
              animation-fill-mode: none;
              transform: scale(1);
            }
          }
        }
        .liBox:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
        .liBox:hover .img {
          transform: scale(1.2);
        }
        .liBox:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 160%;
    background: black;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.detail1 {
  width: 100%;
  font-size: 10px;
  height: 30px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}
</style>